<template>
  <div id="faq">
    <section
      class="cont--faq"
      :style="{ 'background-image': 'url(' + img01 + ')' }"
    >
      <div class="cont--faqbox">
        <div class="wrap--header">FREQUENTLY ASKED QUESTIONS</div>
        <ul class="cont--submenu">
          <li
            class="menu"
            :class="{ active: isActiveNumber == 0 }"
            @click="isActiveNumber = 0"
          >
            academy
          </li>
          <li
            class="menu"
            :class="{ active: isActiveNumber == 1 }"
            @click="isActiveNumber = 1"
          >
            Pro shop & custom fitting
          </li>
        </ul>
        <div class="line"></div>
        <div
          class="wrap--academy-faq animated fadeIn"
          v-show="isActiveNumber == 0"
        >
          <div class="faq--left">
            <ul
              class="wrap--qa"
              v-for="(item, index) in acLeftFaq"
              :key="index"
            >
              <li class="text__q" v-html="item.q"></li>
              <li class="text__a" v-html="item.a"></li>
            </ul>
          </div>
          <div class="faq--right">
            <ul
              class="wrap--qa"
              v-for="(item, index) in acRightFaq"
              :key="index"
            >
              <li class="text__q" v-html="item.q"></li>
              <li class="text__a" v-html="item.a"></li>
            </ul>
          </div>
        </div>
        <div
          class="wrap--proshop-faq animated fadeIn"
          v-show="isActiveNumber == 1"
        >
          <div class="faq--left">
            <ul
              class="wrap--qa"
              v-for="(item, index) in proLeftFaq"
              :key="index"
            >
              <li class="text__q" v-html="item.q"></li>
              <li class="text__a" v-html="item.a"></li>
            </ul>
          </div>
          <div class="faq--right">
            <ul
              class="wrap--qa"
              v-for="(item, index) in proRightFaq"
              :key="index"
            >
              <li class="text__q" v-html="item.q"></li>
              <li class="text__a" v-html="item.a"></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import topImg01 from "@/assets/faq/GM_faq.jpg";
export default {
  name: "faq",
  data: () => {
    return {
      isActiveNumber: 0,
      img01: topImg01,
      acLeftFaq: [
        {
          q: "Is taking golf lesson will really help my game?",
          a:
            "As long as you are patient and have a will to improve your game, GM instructors will guide you to reach your true potential."
        },
        {
          q: "Can I get just practice sessions?",
          a: `Practice sessions with Trackman Launch Monitor are available for purchase and are included in our Junior Elite Program.<br>
<span class="txt-bold">*Range balls are not included.</span>`
        },
        {
          q: "Do you travel to golf tournaments with your students?",
          a:
            "Yes. The Head Instructor B.Y Lee has a lot of valuable knowledge in traveling and tournament preparation from playing on International pro tours for 10 years.  He will guide each student to reach their goals in the competition."
        },
        {
          q: "When does purchased lesson expire?",
          a:
            "The life-span of a lesson is predetermined by the specified lesson plan. Please contact us for more info. "
        },
        {
          q: "What is cancellation policy?",
          a: `<ul class="sub-ul">
                <li>Students are able to cancel their lessons with a 24-hour notice.</li>
                <li>Makeup lessons may not accommodate your most convenient time.</li>
                <li>Any last minute cancellations or no-show will NOT be made up.</li>
                <li>Lessons that are cancelled due to the instructor’s situation will be rearranged within their availability</li>
           </ul>`
        }
      ],
      acRightFaq: [
        {
          q: "What’s the best age to start playing golf?",
          a:
            "According to GolfDigest.com, golf company The Littlest Golfer, believes kids as young as 18-months should be learning the game."
        },
        {
          q: "Do you teach beginners?",
          a: `Yes. At Golf Mecca, we welcome anyone who wants to learn and improve their game.`
        },
        {
          q: "How to apply for Golf Scholarship/ PGA Management program?",
          a:
            "The Founder, Tommy Lee has an in-depth knowledge in guiding students to apply for college. Please contact us for a consultation. "
        },
        {
          q: "Is Golf Mecca Center open all year?",
          a:
            "GM’s official holidays are from Dec 15th to January 15th.*The Pro shop will be closed but coaches still come to the Center for giving lessons to our students."
        },
        { q: "Do I pay taxes on lesson purchase?", a: "Yes. 5% GST." }
      ],
      proLeftFaq: [
        {
          q: "WHY IS THE CUSTOM-FITTING IMPORTANT?",
          a:
            'We all have our own shoe size and we want to get the right size every time we buy shoes.  Why not in Golf?  By getting fit for your clubs, you can make the game easier. For example, by having a driver that is fit to your swing, you can optimize ball flight, which will not only lead to distance gains, but also improved accuracy." At Golf Mecca Center, once an individual’s swing is fully analyzed using the latest swing analysis technologies, all your clubs then fully adjusted in terms of the specifications such as lie, loft, weight, and etc. to your body and swing characteristics.'
        },
        {
          q:
            "IF I PURCHASE CLUBS, IS MY FITTING FEE WAIVED OR APPLIED TO MY PURCHASE?",
          a: "Yes. We will waive the fitting fee if you purchase clubs from us."
        },
        {
          q: "CAN I ORDER ADDITIONAL CLUBS WITHOUT A FITTING?",
          a:
            "Yes. Do not hesitate to contact us on this matter. We will be more than happy to serve your need."
        }
      ],
      proRightFaq: [
        {
          q: "HOW LONG WILL IT TAKE TO GET MY CUSTOM CLUBS?",
          a:
            "Every customer is quoted with a fulfillment time period within 14 days after the order is placed, depending on component availability. Most orders are shipped well within that commitment time, and we do everything to expedite the process as quickly as possible."
        },
        {
          q: "WHAT IF I AM UNHAPPY WITH CLUB?",
          a:
            "If a customer notices the club they receive is not up to their performance expectations, we encourage that player to come back and see us free of charge so we can make the necessary adjustments to produce the results represented at the time of their fitting."
        }
      ]
    };
  }
};
</script>
